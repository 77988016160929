var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flatfinder-leaflet-v1"},[_c('div',{staticClass:"flatfinder-leaflet-v1__aspect",style:({ ..._vm.style, backgroundImage: `url(${_vm.image})` })},[_c('div',{ref:"map",staticClass:"flatfinder-leaflet-v1__map content"}),_c('div',{staticClass:"flatfinder-leaflet-v1__top-right"},[_vm._t("top-right")],2),_c('div',{staticClass:"flatfinder-leaflet-v1__top-left"},[_vm._t("top-left")],2),_c('div',{staticClass:"flatfinder-leaflet-v1__bottom-right"},[_vm._t("bottom-right")],2),_c('div',{staticClass:"flatfinder-leaflet-v1__bottom-left"},[_vm._t("bottom-left")],2)]),(_vm.hover)?_c('Popover',{attrs:{"value":_vm.hover,"mouse":_vm.mouse}}):_vm._e(),(_vm.isMobile)?_c('Carousel',{staticClass:"flatfinder-leaflet-v1__carusel",attrs:{"per-page":1,"navigation-enabled":true,"pagination-enabled":false,"navigation-next-label":_vm.nextLabel,"navigation-prev-label":_vm.prevLabel,"min-swipe-distance":32,"value":_vm.current},on:{"page-change":_vm.onPageChange}},_vm._l((_vm.value),function(item,index){return _c('Slide',{key:index,staticClass:"flatfinder-leaflet-v1__slide"},[_c('Card',{class:[
          'flatfinder-leaflet-v1__slide-card',
          item.reference.status
            ? `flatfinder-leaflet-v1__slide-card-status--${item.reference.status}`
            : '',
          `flatfinder-leaflet-v1__slide-card-model--${item.reference.onModel.toLowerCase()}`,
        ],attrs:{"value":item},on:{"get:action":$ev => _vm.getAction(item)}})],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }