function Currency(
  val,
  locale = document.querySelector('html').getAttribute('locale') ||
    document.querySelector('html').getAttribute('lang'),
  currency = KvassConfig.get('currency'),
  decimals = 0,
) {
  if (!val) return
  return val.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  })
}

export { Currency }
