<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__department">
        <div v-if="company">
          <span class="footer__department-item">Juridisk navn </span>{{ company }}
        </div>
        <div v-if="organizationNumber">
          <span class="footer__department-item">Organisasjonsnummer: </span>{{ organizationNumber }}
        </div>
        <div v-if="address">
          <span class="footer__department-item">Besøksaddresse: </span>
          <a :href="googleMapsLink">{{ address }}</a>
        </div>
        <div v-if="phone"><span class="footer__department-item">Tlf: </span>{{ phone }}</div>
        <div v-if="oppdragsnummer">
          <span class="footer__department-item">Oppdragsnummer: </span>{{ oppdragsnummer }}
        </div>
      </div>

      <div class="footer__policy">
        <span class="footer__privacy">
          <a href="https://privatmegleren.no/personvern" target="_blank">Personvern</a>
          <FontAwesomeIcon :icon="['fas', 'external-link']" size="1x" class="footer__icon" />
        </span>
        <div class="footer__copyright">
          &copy; {{ year }} - {{ $t('pageMadeBy') }}
          <a :href="`https://kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
        </div>
      </div>

      <!-- <router-link to="/" @click.native="scrollToTop">
        <img src="/assets/logo_small_white.png" class="footer__logo" :alt="item.name" />
      </router-link> -->
    </div>
    <div class="footer__container-bottom">
      <span v-html="pageMadeBy"></span>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    company() {
      return this.$path('customFields.department.company', this.item)
    },
    organizationNumber() {
      return this.orgnumberFormater(
        this.$path('customFields.department.organizationNumber', this.item),
      )
    },
    phone() {
      return this.phoneFormater(this.$path('customFields.department.phone', this.item))
    },
    oppdragsnummer() {
      return this.$path('customFields.department.oppdragsnummer', this.item)
    },

    googleMapsLink() {
      // https://www.google.com/maps/search/?api=1&query=latitude,longitude

      const street = this.$path('customFields.department.address.street', this.item) || ''
      const city = this.$path('customFields.department.address.city', this.item) || ''
      const postcode = this.$path('customFields.department.address.postcode', this.item) || ''

      return `https://www.google.com/maps/search/?api=1&query=${street.replace(' ', '+')}${
        postcode ? '+' : ''
      }${postcode}${city ? '+' : ''}${city}`
    },

    address() {
      return this.$path('customFields.department.address.street', this.item)
    },

    pageMadeBy() {
      const kvass = `<a href="https://www.kvass.no?referrer=${this.currentUrl}">Kvass</a>`
      const privatmegleren = `<a href="https://privatmegleren.no/">PrivatMegleren</a>`

      return `Dette digitale prosjektet er utviklet av ${kvass} i samarbeid med ${privatmegleren}`
    },
    currentUrl() {
      return window.location.href
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    orgnumberFormater(x) {
      if (!x) return

      return x
        .toString()
        .replaceAll(' ', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    phoneFormater(x) {
      if (!x) return

      return x
        .toString()
        .replaceAll(' ', '')
        .replace(/\B(?=(\d{2})+(?!\d))/g, ' ')
    },
  },
}
</script>

<style lang="scss">
.footer {
  --space: 5.75rem;
  --item-gap: 1.1rem;
  background-color: #121215;
  color: white;

  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.25rem var(--space);
    max-width: 1200px;
    @include respond-below('phone') {
      flex-direction: column;

      align-items: left;
      padding-top: 2rem;
      padding-bottom: 2rem;

      & > a {
        text-align: center;
      }
    }
  }

  &__department {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include respond-below('phone') {
      order: 1;
    }

    // a {
    //   text-decoration: none;
    //   &:hover {
    //     text-decoration: underline;
    //   }
    // }

    &-item {
      @include respond-below('phone') {
        display: block;
      }
    }
  }

  // a {
  //   color: var(--primary-contrast);
  //   line-height: 20px;
  //   text-decoration: none;
  //   padding: 0;
  //   margin: 0;

  //   &:hover {
  //     text-underline-offset: 5px;
  //     text-decoration: underline;
  //   }
  // }

  p {
    margin: 0;
  }

  &__container {
    &-bottom {
      font-size: 1rem;

      padding: 10px;
      text-align: center;
      width: 100%;
      background-color: var(--secondary);

      a {
        text-underline-offset: 3px;
        color: white;
        text-decoration: underline;
        &:hover {
          color: white;
          text-underline-offset: 3px;
        }
      }
    }
  }

  &__logo {
    width: 115px;
    @include respond-below('phone') {
      order: 0;
    }
  }

  // &__icon {
  //   color: var(--secondary);
  //   margin-right: 10px;
  // }

  &__privacy {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__policy {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    @include respond-below('phone') {
      order: 2;
      text-align: left;
    }
  }
}
</style>
