<template>
  <div class="flatfinder-leaflet-v1">
    <div
      class="flatfinder-leaflet-v1__aspect"
      :style="{ ...style, backgroundImage: `url(${image})` }"
    >
      <div ref="map" class="flatfinder-leaflet-v1__map content"></div>
      <div class="flatfinder-leaflet-v1__top-right">
        <slot name="top-right"></slot>
      </div>
      <div class="flatfinder-leaflet-v1__top-left">
        <slot name="top-left"></slot>
      </div>
      <div class="flatfinder-leaflet-v1__bottom-right">
        <slot name="bottom-right"></slot>
      </div>
      <div class="flatfinder-leaflet-v1__bottom-left">
        <slot name="bottom-left"></slot>
      </div>
    </div>
    <Popover v-if="hover" :value="hover" :mouse="mouse" />
    <Carousel
      :per-page="1"
      :navigation-enabled="true"
      :pagination-enabled="false"
      :navigation-next-label="nextLabel"
      :navigation-prev-label="prevLabel"
      :min-swipe-distance="32"
      :value="current"
      @page-change="onPageChange"
      class="flatfinder-leaflet-v1__carusel"
      v-if="isMobile"
    >
      <Slide v-for="(item, index) in value" :key="index" class="flatfinder-leaflet-v1__slide">
        <Card
          @get:action="$ev => getAction(item)"
          :value="item"
          :class="[
            'flatfinder-leaflet-v1__slide-card',
            item.reference.status
              ? `flatfinder-leaflet-v1__slide-card-status--${item.reference.status}`
              : '',
            `flatfinder-leaflet-v1__slide-card-model--${item.reference.onModel.toLowerCase()}`,
          ]"
        />
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Card from './Card'
import Popover from './Popover'
import Styles from './_colors.scss'
import BrowserApiMixin from './mixins/browser-api'
import { Slide, Carousel } from 'vue-carousel'

Leaflet.CRS.CustomZoom = Leaflet.extend({}, Leaflet.CRS.Simple)

export default {
  mixins: [BrowserApiMixin('flatfinder')],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    image: String,
    dimensions: Object,
    preventNavigation: {
      type: Boolean,
      default: false,
    },
    preventFlatfinderNavigation: {
      type: Boolean,
      default: false,
    },
    preventNavigationOnStatus: {
      //['sold', 'rented']
      type: Array,
      default: () => [],
    },

    isMobile: {
      type: Boolean,
      default: false,
    },
    nextLabel: {
      type: String,
      default:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#FFFFFF" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"/></svg>',
    },
    prevLabel: {
      type: String,
      default:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#FFFFFF" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"/></svg>',
    },
  },
  watch: {
    image: 'init',
    value: 'render',
    $route: 'render',
  },
  data() {
    return {
      promise: null,
      map: null,
      layer: null,
      overlay: {},
      hover: null,
      mouse: { x: 0, y: 0 },
      mouseHandler: null,
      permanentTooltip: true,
      current: 0,
    }
  },
  computed: {
    ratio() {
      return this.dimensions.height / this.dimensions.width
    },
    style() {
      let maxHeight = screen.availHeight - 300
      let maxWidth = Math.min(
        (this.dimensions.width / this.dimensions.height) * maxHeight,
        this.dimensions.width,
      )

      return {
        '--map-ratio': `${this.ratio * 100}%`,
        maxWidth: `${maxWidth}px`,
      }
    },
  },
  methods: {
    onPageChange(currentSlide) {
      if (this.current === currentSlide) return
      this.addLayer(currentSlide)
      this.removeLayer(this.current)

      this.current = currentSlide
    },

    getLayer(layerId) {
      return Object.values(this.layer._layers).find(layer => layer.currentId === layerId)
    },

    addLayer(layerId) {
      const layer = this.getLayer(layerId)
      if (!layer) return

      this.appendLayer(layer, this.value[layerId])
    },

    removeLayer(layerId) {
      const layer = this.getLayer(layerId)
      if (!layer) return

      this.initialLayer(layer, this.value[layerId])
    },

    appendLayer(layer, item) {
      const isProjectOrFlatfinder = ['Project', 'Flatfinder', 'Link'].includes(
        item.reference.onModel,
      )

      layer.setStyle({
        fillColor: this.getColor(item, true),
        color: this.getColor(item, true, '-border'),
      })
      layer._path.classList.add('flatfinder-layer--current-animated')

      if (isProjectOrFlatfinder) {
        if (this.isMobile) return
        layer.getTooltip().getElement().classList.remove('flatfinder-tooltip--muted')
      }
    },

    initialLayer(layer, item) {
      const isProjectOrFlatfinder = ['Project', 'Flatfinder', 'Link'].includes(
        item.reference.onModel,
      )

      layer.setStyle({
        fillColor: this.getColor(item),
        color: this.getColor(item, false, '-border'),
      })
      layer._path.classList.remove('flatfinder-layer--current-animated')

      if (isProjectOrFlatfinder) {
        if (this.isMobile) return
        layer.getTooltip().getElement().classList.add('flatfinder-tooltip--muted')
      }
    },

    getColor(item, hover, type = '-bg') {
      let reference = item.reference
      if (this.$route.params.id === reference.id)
        return Styles[`current${type}${hover ? '-hover' : ''}`]

      switch (reference.onModel) {
        case 'Residential':
          return Styles[`${item.status.toLowerCase()}${type}${hover ? '-hover' : ''}`]
        default:
          return Styles[
            `${reference.onModel.toLowerCase()}-${item.status}${type}${hover ? '-hover' : ''}`
          ]
      }
    },

    getAction(item) {
      this.$emit('click', item.reference)

      if (item.data.redirectUrl) return (window.location.href = item.data.redirectUrl)

      switch (item.reference.onModel) {
        case 'Residential':
          if (
            this.preventNavigation ||
            this.preventNavigationOnStatus.includes(item.reference.status)
          )
            return
          if (item.reference.redirectUrl) return (window.location.href = item.reference.redirectUrl)
          return this.$router.push({ name: 'Residential', params: { id: item.reference.id } })
        case 'Flatfinder':
          if (this.preventFlatfinderNavigation) return
          return this.$emit('switch', item.reference)
        case 'Project':
          if (this.preventNavigation) return
          return (window.location.href = item.reference.url)
        case 'Link':
          if (this.preventNavigation) return
          return (window.location.href = item.reference.href)
      }
    },

    render() {
      this.hover = null
      if (!this.layer) return
      this.layer.clearLayers()

      this.value.forEach((item, index) => {
        const isProjectOrFlatfinder = ['Project', 'Flatfinder', 'Link'].includes(
          item.reference.onModel,
        )

        let layer = Leaflet.GeoJSON.geometryToLayer(item.data, {
          weight: 1,
          color: this.getColor(item, false, '-border'),
          fillColor: this.getColor(item),
          fillOpacity: 0.3,
          opacity: this.getColor(item, false, '-border-opacity'),
          className: `reference-${item.reference.id} status-${
            !isProjectOrFlatfinder ? item.reference.status : 'none'
          } ${isProjectOrFlatfinder ? 'flatfinder-layer--animated' : ''} ${
            this.$route.params.id === item.reference.id ? 'flatfinder-layer--current' : ''
          } flatfinder-layer--delay-${index % 20}`,
        })

        layer._external_id = item.reference.id
        layer.currentId = index

        this.layer.addLayer(layer)

        if (this.$route.params.id === item.reference.id) {
          this.$nextTick(() => (this.current = index))
        }

        layer.on('mouseover', () => {
          if (this.isMobile) return
          this.appendLayer(layer, item)
        })

        layer.on('mouseout', () => {
          this.initialLayer(layer, item)
        })

        if (isProjectOrFlatfinder && !this.isMobile) {
          let tooltip = `<span class="flatfinder-tooltip__title"> ${
            item.reference.name || item.reference.label
          }</span>`

          if (item.data.description)
            tooltip += `<span class="flatfinder-tooltip__description">${item.data.description} </span>`

          layer.bindTooltip(tooltip, {
            direction: 'center',
            permanent: this.permanentTooltip,
            className: `flatfinder-tooltip--muted flatfinder-tooltip flatfinder-tooltip-${
              item.reference.id
            } flatfinder-tooltip-type--${
              item.data.redirectUrl ? 'link' : item.reference.onModel.toLowerCase()
            }`,
          })
        }

        if (['Residential'].includes(item.reference.onModel)) {
          layer.on('mouseover', () => {
            if (this.isMobile) return

            this.hover = item
          })

          layer.on('mouseout', () => {
            if (this.isMobile) return

            this.hover = null
          })
        }

        layer.on('click', () => {
          if (this.isMobile) {
            if (this.current !== index) {
              this.removeLayer(this.current)
              this.current = index
            }
            return this.appendLayer(layer, item)
          }

          return this.getAction(item)
        })

        if (!this.isMobile) return

        if (index === this.current) {
          this.appendLayer(layer, item)
        }
        if (this.$route.params.id === item.reference.id) {
          this.removeLayer(this.current)
          this.appendLayer(layer, item)
        }
      })
    },
    destroyMap() {
      if (!this.map) return

      this.map.off()
      this.map.remove()
    },
    initMap() {
      if (this.map) this.destroyMap()

      this.map = Leaflet.map(this.$refs.map, {
        minZoom: -4,
        maxZoom: 4,
        center: [0, 0],
        zoom: 0,
        zoomSnap: 0.01,
        maxBoundsViscosity: 0.85,
        scrollWheelZoom: false,
        crs: Leaflet.CRS.CustomZoom,
      })

      this.layer = Leaflet.featureGroup()
      this.map.addLayer(this.layer)
    },
    init() {
      if (!this.image) return
      if (!this.map) this.initMap()
      if (this.layer) this.layer.clearLayers()

      let southWest = this.map.unproject([0, this.dimensions.height], 0)
      let northEast = this.map.unproject([this.dimensions.width, 0], 0)
      let bounds = new Leaflet.LatLngBounds(southWest, northEast)

      if (this.map.hasLayer(this.overlay)) this.map.removeLayer(this.overlay)
      this.overlay = Leaflet.imageOverlay(this.image, bounds).addTo(this.map)

      this.map.fitBounds(bounds, { animate: false })
      this.map.setMaxBounds(bounds, { animate: false })

      this.render()
    },
  },

  mounted() {
    this.mouseHandler = ({ clientX: x, clientY: y }) => {
      this.mouse.x = x
      this.mouse.y = y
    }

    document.addEventListener('mousemove', this.mouseHandler)
    this.init()
  },
  beforeDestroy() {
    this.destroyMap()
    document.removeEventListener('mousemove', this.mouseHandler)
  },

  components: {
    Card,
    Slide,
    Carousel,
    Popover,
  },
}
</script>

<style lang="scss">
.flatfinder-leaflet-v1 {
  display: flex;
  justify-content: center;

  @include respond-below('phone') {
    flex-direction: column;
  }

  $spacing: 1rem;

  &__map {
    overflow: hidden;
    background-color: white;
  }

  &__aspect {
    width: 100%;
    @include aspect-ratio(1, 1);
    background-color: css-alpha('primary', 0.1);
    background-size: contain;
    align-self: center;

    @include respond-below('phone') {
      margin-bottom: $spacing * 1.6;
    }

    &:before {
      padding-top: var(--map-ratio);
    }
  }

  &__bottom-right,
  &__bottom-left,
  &__top-right,
  &__top-left {
    position: absolute;
    z-index: 999;
  }

  &__top-right,
  &__top-left {
    top: $spacing;

    @include respond-below('phone') {
      top: 0;
    }
  }

  &__top-right {
    right: $spacing;

    @include respond-below('phone') {
      right: 0;
    }
  }

  &__top-left {
    left: $spacing;

    @include respond-below('phone') {
      left: 0;
    }
  }

  &__bottom-right,
  &__bottom-left {
    bottom: $spacing;

    @include respond-below('phone') {
      bottom: 0;
    }
  }

  &__bottom-right {
    right: $spacing;

    @include respond-below('phone') {
      right: 0;
    }
  }

  &__bottom-left {
    left: $spacing;

    @include respond-below('phone') {
      left: 0;
    }
  }

  &__carusel {
    .VueCarousel-navigation--disabled {
      display: none !important;
    }

    $carousel-border-radius: 50%;
    $carousel-navigation-padding: 5px;

    .VueCarousel-navigation {
      &-next,
      &-prev {
        background-color: rgba(0, 0, 0, 0.3) !important;
        backdrop-filter: blur(10px);

        display: flex;
        justify-content: center;
        align-items: center;

        transform: translate(0, -50%) !important;

        svg {
          width: 0.75rem;
          filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.3));

          path: {
            fill: white;
          }
        }

        &:hover,
        &:focus-visible {
          background-color: rgba(0, 0, 0, 0.4);
        }
        &:active {
          background-color: rgba(0, 0, 0, 0.5);
        }

        &:focus {
          outline: none !important;
        }
      }

      &-next {
        padding-right: $carousel-navigation-padding !important;
        border-radius: $carousel-border-radius 0 0 $carousel-border-radius;
      }

      &-prev {
        border-radius: 0 $carousel-border-radius $carousel-border-radius 0;
        padding-left: $carousel-navigation-padding !important;
      }
    }
  }

  &__slide-card-model--residential {
    height: 100%;
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-container {
    background-color: darken(white, 4%);
  }

  .flatfinder-layer {
    &--animated {
      @include respond-above('phone') {
        animation: flatfinder-pulse 2s infinite;
      }
    }
    &--current {
      @include respond-above('phone') {
        animation: flatfinder-pulse-current 2s infinite;
      }

      &-animated {
        animation: flatfinder-pulse-current 2s infinite;
      }
    }
  }

  @for $i from 0 to 20 {
    .flatfinder-layer--delay-#{$i} {
      animation-delay: 200ms * $i;
    }
  }

  .flatfinder-tooltip {
    max-width: 50ch;
    width: max-content;
    white-space: normal;

    &--muted {
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(4px);
      border-color: transparent;

      .flatfinder-tooltip__description {
        display: none;
      }
    }

    &__description {
      display: block;
      opacity: 0.8;
      letter-spacing: 0.3px;
      animation: flatfinder-tooltip-fade-in 200ms ease-out;
    }

    &__title {
      font-weight: bold;
    }
  }

  .elder-loader__element {
    z-index: 9999;
  }
}
@keyframes flatfinder-pulse-current {
  50% {
    fill-opacity: 0.5;
  }
}

@keyframes flatfinder-pulse {
  50% {
    fill-opacity: 0.5;
  }
}

@keyframes flatfinder-tooltip-fade-in {
  from {
    opacity: 0;
    translate: 0 -0.25rem;
  }
}
</style>
