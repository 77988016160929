<template>
  <div class="key-info">
    <h3>Nøkkelinformasjon</h3>
    <div class="key-info__wrapper">
      <div class="key-info__properties" v-for="(item, index) in content" :key="index">
        <div class="key-info__label">{{ item.label }}</div>
        <div class="key-info__value" v-html="item.value"></div>
      </div>
    </div>
  </div>
</template>

<script>
const i18n = {
  gaardsnummer: 'Gårdsnr.',
  bruksnummer: 'Bruksnr.',
  kommunenummer: 'Kommunenr.',
  seksjonsnummer: 'Seksjonsnr.',
  oppdragsnummer: 'Oppdragsnummer',
  oppholdsrom: 'Oppholdsrom',
  ligningsverdi: 'Ligningsverdi',
  ligningsverdi2: 'Sekundær ligningsverdi',
  andelfellesformue: 'Andel fellesformue',
}

const sortMap = {
  Oppdragsansvarlig: 0,
  'Selger/Utbygger': 1,
  Betegnelse: 2,
  Oppdragsnummer: 3,
  Eier: 4,
  Primærrom: 5,
  Bruksareal: 6,
  'Gårdsnr.': 7,
  'Bruksnr.': 8,
  'Seksjonsnr.': 9,
  'Kommunenr.': 10,
  Byggeår: 11,
  Eiendomstype: 12,
  Eierform: 13,
  Tomteareal: 14,
  Energimerke: 15,
  Etasje: 16,
  Soverom: 17,
  Bad: 18,
  Oppholdsrom: 19,
  'Antall rom': 20,
  Fellesutgifter: 21,
  'Andel fellesformue': 22,
  'Andel fellesgjeld': 23,
  Ligningsverdi: 24,
  'Sekundær ligningsverdi': 25,
  Forretningsfører: 26,
}

export default {
  props: {
    data: {
      residentialProperties: Object,
      properties: Object,
      residentials: Array,
      metadataProperties: Object,
    },
  },

  methods: {
    getLabel(label) {
      const transFormedLabel = i18n[label]
      return transFormedLabel || label
    },
    numberWithSpaces(x) {
      if (!x) return

      return `<span style="white-space:nowrap">${x
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>`
    },
  },

  computed: {
    jointDept() {
      const value = this.data.residentials
        .map(r => r.sale.jointDebt)
        .filter(Boolean)
        .sort((a, b) => a - b)

      let result = null

      if (!value.length && this.data.residentialProperties.jointDebt)
        result = `kr ${this.numberWithSpaces(this.data.residentialProperties.jointDebt.value)}`
      else if (value.length === 1) result = `kr ${this.numberWithSpaces(value[0])}`
      else if (value.length)
        result = `kr ${this.numberWithSpaces(value[0])} til kr ${this.numberWithSpaces(
          value[value.length - 1],
        )}`

      return {
        label: 'Andel fellesgjeld',
        value: result,
      }
    },

    jointCostsPerMonth() {
      const value = this.data.residentials
        .map(r => {
          const property = (r.properties || []).find(p => p.key === 'jointCostsPerMonth') || {}

          if (!property.value) return

          return parseInt(property.value.replace(/\D/g, ''))
        })
        .filter(Boolean)
        .sort((a, b) => a - b)

      let result = null

      if (!value.length && this.data.residentialProperties.jointDebtPerMonth)
        result = `kr ${this.numberWithSpaces(
          this.data.residentialProperties.jointDebtPerMonth.value,
        )} /mnd`
      else if (value.length === 1) result = `kr ${this.numberWithSpaces(value[0])} /mnd`
      else if (value.length)
        result = `kr ${this.numberWithSpaces(value[0])} til kr ${this.numberWithSpaces(
          value[value.length - 1],
        )} /mnd`

      return {
        label: 'Fellesutgifter',
        value: result,
      }
    },

    transformMetadataProperties() {
      const properties = this.data.metadataProperties

      const {
        useableInternalArea,
        useableInternalAreaTo,
        primaryRoomArea,
        primaryRoomAreaTo,
        useableArea,
        useableAreaTo,
        plotArea,
        numberOfBedrooms,
        numberOfBedroomsTo,
        numberOfBaths,
        //  plotPropertyType,
        ownershipType,
        propertyType,
        energyLabel,
        floor,
        constuctionYear,
        numberOfRooms,
      } = this.data.residentialProperties

      if (!properties) return []

      function getProperty(property, residentialProperty, suffix) {
        return getValue(property, suffix) || getValue(residentialProperty, suffix)
      }

      function getValue(value, suffix) {
        if (!value.length || !value[0]) return null
        if (value.length === 1) return `${value[0]} ${suffix}`
        return `${value[0]} ${suffix} - ${value[value.length - 1]} ${suffix}`
      }

      const braI = getProperty(
        properties.useableInternalArea,
        [
          this.$path('value', useableInternalArea),
          this.$path('value', useableInternalAreaTo),
        ].filter(Boolean),
        'm²',
      )

      return [
        {
          label: 'Byggeår',
          value: properties.constructionYear[0] || this.$path('value', constuctionYear),
        },
        {
          label: 'Energimerke',
          value: properties.energyLabel[0] || this.$path('value', energyLabel),
        },
        {
          label: braI ? 'BRA-I' : 'Primærrom',
          value: braI
            ? braI
            : getProperty(
                properties.primaryRoomArea,
                [
                  this.$path('value', primaryRoomArea),
                  this.$path('value', primaryRoomAreaTo),
                ].filter(Boolean),
                'm²',
              ),
        },
        {
          label: 'Bruksareal',
          value: getProperty(
            properties.useableArea,
            [this.$path('value', useableArea), this.$path('value', useableAreaTo)].filter(Boolean),
            'm²',
          ),
        },
        {
          label: 'Tomteareal',
          value: getProperty(properties.plotArea, [this.$path('value', plotArea)], 'm²'),
        },

        {
          label: 'Eiendomstype',
          value: properties.propertyType.length
            ? properties.propertyType.map(type => this.$tc(`propertyType:${type}`)).join(', ')
            : this.$path('value', propertyType),
        },

        {
          label: 'Eierform',
          value: properties.ownershipType.length
            ? properties.ownershipType.map(type => this.$t(`ownershipType:${type}`)).join(', ')
            : this.$path('value', ownershipType),
        },
        {
          label: 'Soverom',
          value: getProperty(
            properties.numberOfBedrooms,
            [this.$path('value', numberOfBedrooms), this.$path('value', numberOfBedroomsTo)].filter(
              Boolean,
            ),
            '',
          ),
        },
        {
          label: 'Bad',
          value: getProperty(
            properties.numberOfBathrooms,
            [this.$path('value', numberOfBaths)],
            '',
          ),
        },
        {
          label: 'Etasje',
          value: getProperty(properties.floor, [this.$path('value', floor)], ''),
        },
        {
          label: 'Antall rom',
          value: getProperty(properties.numberOfRooms, [this.$path('value', numberOfRooms)], ''),
        },
      ].filter(p => p.value)
    },

    content() {
      let data = this.data.properties || {}

      if (data.owner) data.owner.label = 'Eier'

      return [
        ...Object.values(data).map(v => {
          return {
            ...v,
            label: this.getLabel(v.label),
          }
        }),
        ...[this.jointCostsPerMonth, this.jointDept].filter(p => p.value),
        ...this.transformMetadataProperties,
      ].sort((a, b) => {
        return sortMap[a.label] - sortMap[b.label]
      })
    },
  },
}
</script>

<style lang="scss">
.key-info {
  @include respond-above('tablet') {
    padding-right: 2rem;
  }

  position: relative;

  @keyframes fill-height {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }

  // @include respond-above('tablet') {
  //   &:after {
  //     position: absolute;
  //     top: 0px;
  //     right: 0px;
  //     content: '';
  //     width: 1px;
  //     border: none;
  //     height: 0%;
  //     background-color: var(--primary);
  //     //    background: url('../../assets/vertical_line.svg') no-repeat;
  //     animation: 0.8s ease-in-out 0.6s 1 normal forwards running fill-height;
  //   }
  // }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    @include respond-above('tablet') {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include respond-below('phone') {
      gap: 1rem;
      grid-template-columns: 1fr;
    }
  }

  &__properties {
    // display: flex;
    // flex-direction: column;
  }
  &__label {
    font-weight: bold;
  }
  &__value {
    //color: var(--primary);
  }
}
</style>
