<template>
  <div
    class="flatfinder-leaflet-v1-card"
    :class="[
      { 'flatfinder-leaflet-v1-card--visible': reference.id },
      popper
        ? 'flatfinder-leaflet-v1-card-theme--popper'
        : 'flatfinder-leaflet-v1-card-theme--carusel',
      {
        'flatfinder-leaflet-v1-card-theme--overlay':
          ['Flatfinder', 'Project', 'Link'].includes(reference.onModel) &&
          (!value.data || !value.data.description),
      },
      reference.status ? `flatfinder-leaflet-v1-card-status--${reference.status}` : '',
      `flatfinder-leaflet-v1-card-type--${
        value.data && value.data.redirectUrl ? 'link' : reference.onModel.toLowerCase()
      }`,
    ]"
  >
    <div class="flatfinder-leaflet-v1-card__wrapper">
      <div
        class="flatfinder-leaflet-v1-card__cover"
        :style="{ backgroundImage: `url(${cover})` }"
      ></div>
      <div class="flatfinder-leaflet-v1-card__content grow-1">
        <h3 class="flatfinder-leaflet-v1-card__title">{{ reference.name }}</h3>
        <div
          v-if="value.data && value.data.description"
          class="flatfinder-leaflet-v1-card__description"
          v-html="value.data.description"
        ></div>

        <div v-if="properties.length" class="flatfinder-leaflet-v1-card__properties-wrapper">
          <div
            v-for="item in properties"
            :key="item.key"
            :data-id="item.key"
            class="flatfinder-leaflet-v1-card__properties"
          >
            <b class="mr-1">{{ item.label || $tc(item.key) }}</b>
            <span>{{ item.formatted }}</span>
          </div>
        </div>

        <div
          class="flatfinder-leaflet-v1-card__price-wrapper"
          v-if="['Residential'].includes(reference.onModel)"
        >
          <div class="flatfinder-leaflet-v1-card__price">
            {{ price }}
          </div>

          <div
            v-if="hasPrice && reference.status === 'sale'"
            class="flatfinder-leaflet-v1-card__price-label"
          >
            <div v-if="$path('sale.jointDebt', reference)">
              <template>+ {{ $t('jointDebt').toLowerCase() }}</template>
              <template v-if="jointDebt"> ({{ jointDebt | Currency }})</template>
            </div>
            <template v-if="$path('sale.fee.included', reference)">
              {{ $t('residentialSaleFeeIncluded', { value: null }) }}
            </template>
            <template v-else> + {{ $t('residentialSaleFee').toLowerCase() }} </template>
            <template v-if="fee">({{ fee | Currency }})</template>
            <strong v-if="total && total !== price" class="summary__price-total">{{
              `${$t('totalPrice')}: ${total}`
            }}</strong>
          </div>
        </div>

        <ButtonComponent
          class="flatfinder-leaflet-v1-card__button"
          v-if="!popper"
          :label="buttonLabel"
          theme="primary"
          :icon="['fas', !isCurrent ? 'arrow-right' : 'map-pin']"
          :disabled="isCurrent"
          @click.native="$emit('get:action')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Currency, path } from './utils'

export default {
  props: {
    value: Object,
    popper: {
      type: Boolean,
      default: false,
    },
  },

  filters: {
    Currency,
  },
  computed: {
    isCurrent() {
      return this.$route.params.id === this.reference.id
    },
    reference() {
      return path('value.reference', this)
    },

    buttonLabel() {
      if (this.isCurrent) {
        return this.$t('youAreHere')
      }
      let label

      switch (this.reference.onModel) {
        case 'Residential':
          label = (
            path('customLabels.propertyType', KvassConfig) ||
            path('reference.propertyType.0.formatted', this) ||
            this.reference.name ||
            ''
          ).toLowerCase()
          break
        default:
          label = this.reference.name
      }

      return this.$t('lookAt', {
        x: label,
      })
    },

    properties() {
      return (path('reference.properties', this) || []).slice(0, 3)
    },
    fee() {
      return path('reference.sale.fee.value', this)
    },
    total() {
      let total = this.$path('reference.sale.value')
      if (!total) return

      if (!this.$path('reference.sale.fee.included') && this.fee) total += this.fee
      if (this.jointDebt) total += this.jointDebt

      return Currency(total)
    },
    jointDebt() {
      return path('reference.sale.jointDebt', this)
    },
    cover() {
      const thumbnailType = path('value.data.thumbnailType', this) || 'cover'
      const image =
        thumbnailType === 'floorplan'
          ? path('reference.media.floorplan.0.url', this)
          : path('reference.media.cover.0.url', this) || path('reference.image.url', this)
      return image
    },
    hasPrice() {
      return Boolean(path('reference.sale.value', this))
    },
    price() {
      let price = Currency(path('sale.value', this.reference))

      if (!this.reference.status) return

      switch (this.reference.status) {
        case 'sale':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return price
        case 'reserved':
          return `${this.$t(
            'residentialStatus:' + this.reference.sale.type + '.' + this.reference.status,
          )}${price && !KvassConfig.get('siteSettings.hideReservedPrice') ? ` (${price})` : ''}`
        case 'rent':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return `${price} / ${this.$t('multiplicatorMonthly')}.`
        default:
          return this.$t(
            'residentialStatus:' + this.reference.sale.type + '.' + this.reference.status,
          )
      }
    },
  },
}
</script>

<style lang="scss">
.flatfinder-leaflet-v1-card {
  opacity: 0;
  &--visible {
    opacity: 1;
  }

  &-theme {
    &--overlay {
      .flatfinder-leaflet-v1-card {
        &__wrapper {
          padding: 0;
        }

        &__cover {
          width: 100%;
        }
        &__content {
          margin-top: 0;
          .flatfinder-leaflet-v1-card__title {
            display: none;
          }
          color: white;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.3);
          width: 100% !important;
          height: 100%;
          align-items: center;
          max-width: 100% !important;
        }
      }
    }

    &--popper {
      z-index: 999;
      pointer-events: none;
      position: absolute;
      transition: opacity 150ms ease-out;
      .flatfinder-leaflet-v1-card {
        &__cover {
          height: 150px;
        }

        &__content {
          padding: 1rem;
        }
      }
    }

    &--carusel {
    }
  }

  &__wrapper {
    border-radius: var(--border-radius);
    background-color: white;
    color: $dark;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: 0.75em;
    position: relative;

    height: inherit;
  }

  &__cover {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-color: css-alpha('primary', 0.07);
  }

  &__button {
    margin-top: auto;

    &.elder-button--icon {
      justify-content: center;
    }
    .elder-button__label {
      flex-grow: 0;
    }
  }

  &__description > :first-child {
    margin-top: 0;
  }
  &__description > :last-child {
    margin-bottom: 0;
  }

  &__description {
    max-width: 200px;
  }

  &__title {
    margin: 0;
    line-height: 1;
    font-size: 1.4em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.75rem;
    min-width: 250px;
    padding: 1.5rem 2rem;
  }

  &__properties {
    display: flex;
    justify-content: space-between;
  }

  &__price {
    line-height: 1;
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;

    &-label {
      opacity: 0.7;
      margin-top: 0.3em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.1em;
    }
  }

  &__fee {
    opacity: 0.5;
    margin-top: 0.5em;
  }
}
</style>
