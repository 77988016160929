import { path as RPath } from 'ramda'

function path() {
  let [key, obj, ...args] = arguments

  key = typeof key === 'string' ? key.split('.') : key

  return RPath.apply(this, [key, obj, ...args])
}

function isNumeric(str) {
  if (typeof str === 'number') return true
  if (typeof str != 'string') return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

function Debounce(func, wait, immediate) {
  let timeout
  return function () {
    let context = this,
      args = arguments
    let later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

function getDimensions(url) {
  return new Promise((resolve, reject) => {
    if (!url) return reject(new Error('Url is required'))
    let img = new Image()
    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = reject
    img.src = url
  })
}

function Currency(
  val,
  locale = document.querySelector('html').getAttribute('locale') ||
    document.querySelector('html').getAttribute('lang'),
  currency = KvassConfig.get('currency'),
  decimals = 0,
) {
  if (!val) return
  return val.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  })
}

export { path, getDimensions, Currency, Debounce, isNumeric }
