import { GraphQLClient } from 'graphql-request'
import Queries from './graphql/queries'

let URL = document.head.querySelector('[property="og:url"]')
URL = URL ? URL.getAttribute('content').split('/residential')[0] : ''

function getFlatfinder(payload) {
  return new GraphQLClient(URL + '/api/graphql', {
    headers: { 'accept-language': document.querySelector('html').getAttribute('lang') },
  })
    .request(Queries.Flatfinder, payload)
    .then(data => data.Flatfinder)
}

function getFlatfinderMetadata(payload) {
  return new GraphQLClient(URL + '/api/graphql', {
    headers: { 'accept-language': document.querySelector('html').getAttribute('lang') },
  }).request(Queries.FlatfinderMetadata, payload)
}

export { getFlatfinder, getFlatfinderMetadata }
