import { GraphQLClient } from 'graphql-request'
import Queries from './graphql/queries'
import { mergeDeepRight } from 'ramda'

function getResidentials(payload) {
  let URL = document.head.querySelector('[property="og:url"]')
  URL = URL ? URL.getAttribute('content').split('/residential')[0] : ''

  return new GraphQLClient(URL + '/api/graphql', {
    headers: {
      'accept-language': document.querySelector('html').getAttribute('lang'),
    },
  })
    .request(Queries.Residentials, mergeDeepRight({ filter: { status: ['!draft'] } }, payload))
    .then(data => data.ResidentialsPaginate)
}

export { getResidentials }
