<template>
  <Card :value="value" :popper="true" class="flatfinder-leaflet-v1-popper-card" />
</template>

<script>
import { createPopper } from '@popperjs/core'
import Card from './Card'
export default {
  props: {
    value: Object,
    mouse: Object,
  },
  data() {
    return {
      popper: null,
    }
  },
  watch: {
    mouse: {
      handler() {
        if (!this.popper) return
        this.popper.update()
      },
      deep: true,
    },
  },
  computed: {
    boundingRect() {
      return () => ({
        width: 0,
        height: 0,
        top: this.mouse.y,
        right: this.mouse.x,
        bottom: this.mouse.y,
        left: this.mouse.x,
      })
    },
  },
  mounted() {
    this.popper = createPopper(
      { getBoundingClientRect: this.boundingRect, contextElement: this.$parent.$el },
      this.$el,
      {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 30],
            },
          },
        ],
      },
    )
  },
  beforeDestroy() {
    if (this.popper) this.popper.destroy()
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.flatfinder-leaflet-v1-popper-card {
}
</style>
