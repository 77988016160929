var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isValid)?_c('div',{staticClass:"range-slider"},[(_vm.label)?_c('label',{staticClass:"range-slider__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('input',{ref:"leftRef",class:[
      'range-slider__input range-slider__input--left',
      { 'range-slider__input--elevated': _vm.isOverlap && _vm.selected === 0 },
    ],attrs:{"type":"range","step":_vm.step,"min":_vm.min,"max":_vm.max},domProps:{"value":_vm.getValue(0)},on:{"input":$ev => _vm.handleValueChange($ev, 0)}}),_c('input',{ref:"rightRef",class:[
      'range-slider__input range-slider__input--right',
      { 'range-slider__input--elevated': _vm.isOverlap && _vm.selected === 1 },
    ],attrs:{"type":"range","step":_vm.step,"min":_vm.min,"max":_vm.max},domProps:{"value":_vm.getValue(1)},on:{"input":$ev => _vm.handleValueChange($ev, 1)}}),_c('div',{staticClass:"range-slider__slider"},[_c('div',{staticClass:"range-slider__slider-track"}),_c('div',{ref:"rangeRef",staticClass:"range-slider__slider-range"}),(_vm.numberComp)?_c('div',{staticClass:"range-slider__slider__values"},[_c('div',{staticClass:"range-slider__slider__values__value range-slider__slider__values__value-left-value"},[_vm._v(" "+_vm._s(_vm.numberComp[0])),(_vm.hasSuffix)?_c('span',{staticClass:"range-slider__slider__values--suffix",attrs:{"for":_vm.id}},[_vm._t("suffix",function(){return [_vm._v(_vm._s(_vm.suffix))]})],2):_vm._e()]),_c('div',{staticClass:"range-slider__slider__values__value range-slider__slider__values__value-right-value"},[_vm._v(" "+_vm._s(_vm.numberComp[1])),(_vm.hasSuffix)?_c('span',{staticClass:"range-slider__slider__values--suffix",attrs:{"for":_vm.id}},[_vm._t("suffix",function(){return [_vm._v(_vm._s(_vm.suffix))]})],2):_vm._e()])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }